.ALERT{

    position: relative;
    .ALERT-suc{
        width: 350px;
        height:90px;
        position: absolute;
        border-radius: 15px;
        z-index: 500;
        top: 10px;
        left: 15px;
        background-color: #65FBD2;
        animation: alert 300ms forwards;
    }

    .ALERT-err{
        width: 350px;
        height:90px;
        position: absolute;
        border-radius: 15px;
        z-index: 500;
        top: 10px;
        left: 15px;
        background-color: #ed7777;
        animation: alert 300ms forwards;
    }


    @keyframes alert {
        from{
            transform: translateX(-150px);
        }
        to{
            transform: translateX(0px);
        }
    }

    @keyframes alert2 {
        from{
            transform: translateX(-10px);
        }
        to{
            transform: translateX(0px);
        }
    }
}

@media screen and (max-width : 900px) {
    .ALERT{
        width: 100%;
      .ALERT-suc{
        position: absolute;
        z-index: 500;
        top: 10px;
        left: 10%;
        right: 10%;
        width: 80%;
        animation: alert2 400ms forwards;
      }  
      .ALERT-err{
        position: absolute;
        z-index: 500;
        top: 10px;
        left: 10%;
        right: 10%;
        width: 80%;
        animation: alert2 400ms forwards;
      }  
    }
}