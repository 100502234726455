.CHAT {
    width: 90%;
    overflow-x: hidden;
    height: 550px;

    .ShowChat {
        overflow-x: hidden;
        overflow-y: auto;
        height: 400px;
        border-radius: 16px;
        background-color: white;
        width: 100%;

        &::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px grey;
            border-radius: 16px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: black;
            border-radius: 10px;
        }

        .userMess{

            word-break: break-all;
            margin: 15px 0px !important;

            .userMess-text{
                width: auto;
                min-width: 55px;
                max-width: 80%;
                border-radius: 15px;
                background-color: whitesmoke;

            }
        }

        
    .adminMess{
        margin: 15px 0px !important;
        word-wrap: break-word;

        .adminMess-text{
           width: auto;
           min-width: 55px;
           max-width: 80%;
           border-radius: 15px; 
        }
    }
        

       
    


    
}


.TYPEMESS {
    border: none;
    outline: none;
    border-radius: 16px;
}
}

@media screen and (max-width : 768px) {
    .CHAT {
        width: 90%;
        overflow-x: hidden;
        height: 550px;

        .ShowChat {
            overflow-x: hidden;
            overflow-y: auto;
            width: 100% !important;
            height: 400px;
            border-radius: 16px;
            background-color: white;

        }


        .TYPEMESS {
            border: none;
            outline: none;
            border-radius: 16px;
        }
    }
}