.SUPPORT{
    min-height: 700px;
    height: auto;
    border: 1px solid #E6E6E8;
    overflow: hidden;
    position: relative;
    .supportList{
        overflow-x: auto ;
        width: 100%;

        
        th{
            padding: 12px 3px;
            text-align: right;
            background-color: white;
             
        }

        tr{
            min-width: 100%;
            height: 52px;
            &:nth-child(odd) {background-color: rgba(240, 179, 29, 0.08);}
        }
        td{
            padding: 0px 5px;
            .accAuth {
                background: #27AE60;
                border-radius: 8px;
                line-height: 28px;
                width: 90px;
                height: 29px;
                color: white;
                border: none;
                &:hover{
                    background-color: darken($color: #27AE60, $amount: 10%);
                }

            }

            .rejAuth {
                background: #FFFFFF;
                border: 1px solid #EB5757;
                box-sizing: border-box;
                border-radius: 8px;
                width: 71px;
                height: 29px;
                line-height: 28px;
                color: #EB5757;


            }
            .accSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(21, 207, 116, 0.2);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #2CB87A;
            }
            .rejSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(245, 91, 93, 0.3);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #F55B5D;
                 
            }
            .waitSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(255, 165, 0, 0.2);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #FF9C00;
                 
            }

            .watchSup{
                width: 139px;
                height: 29px;
                background: #FFFFFF;
                border: 1px solid #F0B31D;
                box-sizing: border-box;
                border-radius: 8px;
                outline: none;

                &:hover{
                    background-color: darken($color: #FFFFFF, $amount: 15%);
                }
            }

            .answerSup{
                width: 139px;
                height: 29px;
                background: #F0B31D;
                border-radius: 8px; 
                outline: none;
                border: none;
                &:hover{
                    background-color: darken($color: #F0B31D, $amount: 10%);
                }

            }
            
        }
    }
}

@media  (max-width : 900px) {
    .SUPPORT{
        min-height: 200px;
        height: auto;
    }
}