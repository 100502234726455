.CHECKBOX{
    .activeBTN{
        border-radius: 16px;
        padding: 12px;
        background-color: #F0B31D;
        border: none;
        outline: none;
        width: 150px;

        &:hover{
            background-color: darken($color: #F0B31D, $amount: 15%);
        }

    }

    .inactiveBTN{
        border-radius: 16px;
        padding: 12px;
        background-color: #dcdcdc;
        border: none;
        outline: none;
        color: black;
        width: 150px;

        &:hover{
            background-color: #F0B31D;
            color:white;
        }
    }
}