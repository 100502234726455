.header{
    height: 64px;
    max-height: 100px;
    overflow-x: hidden;

    .NOTIF{
        width: 40px;
        height: 40px; 
        background: #F5F5F5;
        border-radius: 50%;
        margin-left: 10px;
    }
}