.DELETEMODAL {
    position: fixed;
    z-index: 200;
    height: 200px;
    border: 1px solid #eee;
    border-radius: 15px;
    box-shadow: 0 2px 2px #ccc;
    background-color: #ffffff;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    top: 20%;
    left: 40%;
    width: 20%;
    transition: all 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ModalOpen {
        animation: openModal 0.3s ease-out forwards;

    }

    .ModalClose {
        opacity: 0;
        transform: translateY(-100%);
    }

    @keyframes openModal {
        0%{
            opacity:0;
            transform: translateY(-100%);
        }
        50%{
            opacity:1;
            transform: translateY(00%);
        }
        100%{
            opacity:1;
            transform: translateY(0);
        }
    }

}

@media screen and (max-width : 900px) {

    .DELETEMODAL{
        width: 80%;
        left: 10%;
        right: 10%;
        height: auto;
    }


}