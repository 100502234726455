// .SIDEBAR{
//     width: 236px;
//     height: 850px;
//     max-height: auto;
//     overflow-x: hidden;
//     overflow-y: auto;
//     scrollbar-gutter: stable;

//     &::-webkit-scrollbar {
//         width: 4px;

//       }
      
//       /* Track */
//       &::-webkit-scrollbar-track {
//         box-shadow: inset 0 0 5px grey; 
//         border-radius: 8px;
//       }
       
//       /* Handle */
//       &::-webkit-scrollbar-thumb {
//         background: #F0B31D; 
//         border-radius: 16px;
//       }
      
//     .decoration-none{
//         text-decoration: none;
//     }
//     .active{
//         color:#F0B31D ;
//     }


//     .sideLi{
//         list-style: none;
//         color: #dcdcdc;
//         line-height: 42px;
//         letter-spacing: 0.2px;

//         &:hover{
//             color: #F0B31D;
//         }

//         &:active{
//             color: #F0B31D;
//         }
        
//     }

//     .sideLIActive{
//         list-style: none;
//         color: #F0B31D;
//         line-height: 42px;
//         letter-spacing: 0.2px; 
//     }
// }

// .resSIDEBAR{
//     width: 236px;
//     height: 100%;
//     max-height: auto;
//     position: absolute;
//     top: 0px;
//     bottom: 0px;
//     right: 0px;
//     z-index: 1000;
//     margin-bottom: -1000px; /* any large number will do */
//   padding-bottom: 1000px;
//   overflow-x: hidden;

//     .decoration-none{
//         text-decoration: none;
//     }
//     .active{
//         color:#F0B31D ;
//     }

// // asdfasdf
//     .sideLi{
//         list-style: none;
//         color: #dcdcdc;
//         line-height: 42px;
//         letter-spacing: 0.2px;

//         &:hover{
//             color: #F0B31D;
//         }

//         &:active{
//             color: #F0B31D;
//         }
        
//     }
// }

.sidebar {
    height: 100vh;
    border-radius: 10px;
    
    .sidebar-content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 10px;
      
      ::-webkit-scrollbar {
        width: 0.25rem;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: #fff5;
        border-radius: 50rem;
      }
      > *:not(.bg-img) {
        position: relative;
      }
      ul {
        list-style:none;
        color: #dcdcdc;
        overflow-x: hidden;

            .sideLi{
        list-style: none;
        color: #dcdcdc;
        line-height: 42px;
        letter-spacing: 0.2px;

        &:hover{
            color: #F0B31D;
        }

        &:active{
            color: #F0B31D;
        }
        
    }

    .sideLIActive{
        list-style: none;
        color: #F0B31D;
        line-height: 42px;
        letter-spacing: 0.2px; 
    }
      }
    }
  }


  @media (max-width: 992px) {

      .sidebar {
        position: fixed;
        pointer-events: none;
        background-color: transparent;
        z-index: 10;
        top: 0px;
        right: -10px;
        border-radius: 0px;
        transition: background-color 250ms;
        .sidebar-content {
          width: 100%;
          background-color: transparent;
          transform: translateX(100%);
          transition: transform 250ms;
          border-radius: 0px;

        }
        &.active {
          pointer-events: all;
          background-color: transparent;
          .sidebar-content {
            transform: translateX(0);
            width: 100%;
          }
        }
      }
    
  }