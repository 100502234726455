.EDITMESSAGE{
    min-height: 660px;
    height: auto;
    border: 1px solid #E6E6E8;
    overflow: hidden;
    .editMessage{
        overflow-x: auto ;
        width: 100%;
        
        th{
            padding: 12px 3px;
            text-align: right;
            background-color: white;
             
        }

        tr{
            min-width: 100%;
            height: 52px;
            &:nth-child(odd) {background-color: rgba(240, 179, 29, 0.08);}
        }
        td{
            padding: 0px 3px;
            
        }
    }
}

@media  (max-width : 900px) {
    .EDITMESSAGE{
        min-height: 200px;
        height: auto;
    }
}