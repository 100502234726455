.BANKCARDS {
    height: auto;
    min-height: 650px;
    border: 1px solid #E6E6E8;
    overflow: hidden;

    .BANKCARDSList {
        overflow-x: auto;
        width: 100%;

        th {
            padding: 12px 2px;
            text-align: right;
            
            background-color: white;

        }

        tr {
            min-width: 100%;
            height: 52px;

            &:nth-child(odd) {
                background-color: rgba(240, 179, 29, 0.08);
            }
        }

        td {
            padding: 0px 5px;

            .accRej {
                background-color: #A82070;
                border-radius: 8px;
                line-height: 28px;
                width: 90px;
                height: 29px;
                color: white;
                border: none;
                &:hover{
                    background-color: darken($color: #A82070, $amount: 10%);
                }

            }

            // .rejAuth {
            //     background: #FFFFFF;
            //     border: 1px solid #EB5757;
            //     box-sizing: border-box;
            //     border-radius: 8px;
            //     width: 71px;
            //     height: 29px;
            //     line-height: 28px;
            //     color: #EB5757;


            // }
        }
    }
}

@media (max-width : 900px) {
    .BANKCARDS {
        min-height: 200px;
        height: auto;
    }
}