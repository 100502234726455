.INPUTSETTING{

    .INPUTSET{
        background: #FFFFFF;
        border: 1px solid #D8E1EA;
        box-sizing: border-box;
        border-radius: 8px;

        p{
            line-height: 28px;
            color: #2E2E2E ;
        }
    }
    
}