$white: white;
$blue: #657fe4;
$dark-blue: #012766;
$dark-gray:#2F2F31;;
$light-gray: #ebeff4;
$light-yellow: #f8da92;
$tableTd : #6D6E71;
$dark-secondary : #2E2E2E;
$bluee : #23509A;
$yellow : #F0B31D;
$purple : #A82070;
$light-blue :  #DAE1EC4F;  
$gray-blue : rgba(218, 225, 236, 0.31);
$red :  #F85858;
$grayy : #E5E5EB;
$pink : #FF8ACA;




$primary: #f0b31d;
$info: #00beff;
$success: #60C08E;
$secondary: #818181;
$dark: #364251;
$light: #f1f2f1;

// $light-primary: #f8db93;

$gradient-left-to-right: linear-gradient(to right, #e09230, $primary);
$gradient-top-to-bottom: linear-gradient(to bottom, #e09230, $primary);

$alert-border-radius: 1rem;
$alert-margin-bottom: 0;
$alert-bg-scale: -85%;
$alert-border-scale: -0%;
$alert-color-scale: -100%;

$custom-colors: (
  "white": $white,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "primary": $primary,
  "dark": $dark,
  "light": $light,
  "light-yellow": $light-yellow,
  "secondary": $secondary,
  "dark-gray": $dark-gray,
  "light-gray": $light-gray,
  "info": $info,
  "success": $success,
  "dark-secondary" : $dark-secondary,
  "bluee" : $bluee,
  "yellow" : $yellow,
  "purple" : $purple,
  "tableTd" : $tableTd,
  "light-blue" : $light-blue,
  "gray-blue" : $gray-blue,
  "red" : $red,
  "grayy" : $grayy,
  "pink" : $pink

 
);