.LOGIN{
    overflow-x: hidden;
    background-image:linear-gradient(90deg, rgba(168, 32, 112, 0.6) 0%, rgba(240, 179, 29, 0.6) 100%) ,  url('../../assets/login.png');
    // background-color: linear-gradient(90deg, rgba(168, 32, 112, 0.6) 0%, rgba(240, 179, 29, 0.6) 100%);
    background-repeat: no-repeat; 
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;

    .login-form{
        border-radius: 15px;
    }
}