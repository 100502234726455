.INCREASECREDIT {
    height: auto;
    min-height: auto;
    border: 1px solid #E6E6E8;
    overflow: hidden;

    .pay-order {

        border: none !important;
        background: #A82070  !important;
        color: white  !important;
    }




    .rightBTN {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        width: 100px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .centerBTN {
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .leftBTN {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .SearchOrder {
        background: rgba(185, 189, 197, 0.31);
        width: 250px;
        height: 38px;
        min-width: 90%;
    }

    .userOrder-pur {
        height: 40px;
        width: 150px;
    }

    .payList {
        overflow-x: auto;

        th {
            padding: 12px 3px;
            text-align: right;
            background-color: white;

        }

        tr {

            height: 52px;

            &:nth-child(odd) {
                background-color: rgba(240, 179, 29, 0.08);
            }
        }

        td {
            padding: 0px 3px;
        }
    }
}

@media (max-width : 900px) {
    .INCREASECREDIT {
        height: auto;
    }
}