.MANAGE_TETHER{
    width: 100%;
    overflow-x: hidden;

    .addTether-div{
        background: #FFFFFF;
        border: 1px solid hsl(240, 4%, 91%);
        box-sizing: border-box;
        border-radius: 16px;
        position: relative;

        .divTITLE{
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            
            border-radius: 16px 16px 0px 0px;
            height: 42px;
        }
    }
}