.ALLPAY {
    height: auto;
    min-height: 700px;

    overflow: hidden;

    .all-order {

        border: none !important;
        background: #A82070  !important;
        color: white  !important;
    }




    .rightBTN {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        width: 100px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .centerBTN {
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .leftBTN {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .SearchAllPay {
        background: rgba(185, 189, 197, 0.31);
        width: 250px;
        height: 38px;
        min-width: 90%;
    }

    .Allpay-pur {
        height: 40px;
        width: 150px;
    }

    .allList {
        overflow-x: auto;

        th {
            padding: 12px 3px;
            text-align: center;
            background-color: white;

        }

        tr {

            height: 52px;

            &:nth-child(odd) {
                background-color: rgba(240, 179, 29, 0.08);
            }
        }

        td {
            padding: 0px 3px;
            text-align: center;
            .accAuth {
                background: #27AE60;
                border-radius: 8px;
                line-height: 28px;
                width: 90px;
                height: 29px;
                color: white;
                border: none;
                &:hover{
                    background-color: darken($color: #27AE60, $amount: 10%);
                }

            }

            .rejAuth {
                background: #FFFFFF;
                border: 1px solid #EB5757;
                box-sizing: border-box;
                border-radius: 8px;
                width: 71px;
                height: 29px;
                line-height: 28px;
                color: #EB5757;


            }
            .accSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(21, 207, 116, 0.2);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #2CB87A;
            }
            .rejSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(245, 91, 93, 0.3);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #F55B5D;
                 
            }
            .waitSup{
                width: 98px;
                height: 27px;
                border-radius: 4px;
                background: rgba(255, 165, 0, 0.2);
                font-weight: 400;
                font-size: 12px;
                line-height: 19px;
                text-align: center;
                color: #FF9C00;
                 
            }

            .watchSup{
                width: 139px;
                height: 29px;
                background: #FFFFFF;
                border: 1px solid #F0B31D;
                box-sizing: border-box;
                border-radius: 8px;
                outline: none;

                &:hover{
                    background-color: darken($color: #FFFFFF, $amount: 15%);
                }
            }

            .answerSup{
                width: 139px;
                height: 29px;
                background: #F0B31D;
                border-radius: 8px; 
                outline: none;
                border: none;
                &:hover{
                    background-color: darken($color: #F0B31D, $amount: 10%);
                }

            }
        }
    }
}

@media (max-width : 900px) {
    .ALLPAY {
        height: auto;
    }
}