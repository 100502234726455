.BtnSetting{
    width: 225px;
    height: 40px;
    border-radius: 8px;
    background: #F0B31D;
    line-height: 28px;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover{
        background-color: darken($color: #F0B31D, $amount: 10%);
    }
}