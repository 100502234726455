// .USERORDER {
//     height: 650px;
//     min-height: auto;
//     border: 1px solid #E6E6E8;
//     overflow: hidden;

//     .active-order {

//         border: none !important;
//         background: #A82070  !important;
//         color: white  !important;
//     }




//     .rightBTN {
//         border-top-left-radius: 30px;
//         border-bottom-left-radius: 30px;
//         background: #FFFFFF;
//         height: 40px;
//         width: 100px;
//         border: 1px solid rgb(225, 225, 225);
//         &:hover{
//             background: darken($color: #A82070, $amount: 10%);
//             color: white;
//         }

//     }

//     .centerBTN {
//         background: #FFFFFF;
//         height: 40px;
//         border: 1px solid rgb(225, 225, 225);
//         &:hover{
//             background: darken($color: #A82070, $amount: 10%);
//             color: white;
//         }

//     }

//     .leftBTN {
//         border-top-right-radius: 30px;
//         border-bottom-right-radius: 30px;
//         background: #FFFFFF;
//         height: 40px;
//         border: 1px solid rgb(225, 225, 225);
//         &:hover{
//             background: darken($color: #A82070, $amount: 10%);
//             color: white;
//         }

//     }

//     .SearchOrder {
//         background: rgba(185, 189, 197, 0.31);
//         width: 250px;
//         height: 38px;
//         min-width: 90%;
//     }

//     .userOrder-pur {
//         height: 40px;
//         width: 150px;
//     }

//     .orderList {
//         overflow-x: auto;

//         th {
//             padding: 12px 3px;
//             text-align: right;
//             background-color: white;

//         }

//         tr {

//             height: 52px;

//             &:nth-child(odd) {
//                 background-color: rgba(240, 179, 29, 0.08);
//             }
//         }

//         td {
//             padding: 0px 3px;
//             .accSup{
//                 width: 98px;
//                 height: 27px;
//                 border-radius: 4px;
//                 background: rgba(21, 207, 116, 0.2);
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 19px;
//                 text-align: center;
//                 color: #2CB87A;
//             }
//             .rejSup{
//                 width: 98px;
//                 height: 27px;
//                 border-radius: 4px;
//                 background: rgba(245, 91, 93, 0.3);
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 19px;
//                 text-align: center;
//                 color: #F55B5D;
                 
//             }
//             .waitSup{
//                 width: 98px;
//                 height: 27px;
//                 border-radius: 4px;
//                 background: rgba(255, 165, 0, 0.2);
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 19px;
//                 text-align: center;
//                 color: #FF9C00;
                 
//             }
//         }
//     }
// }

// @media (max-width : 900px) {
//     .USERORDER {
//         height: auto;
//     }
// }


.SELLORDER {
    height: auto;
    min-height: auto;
    border: 1px solid #E6E6E8;
    overflow: hidden;

    .sellOrder-order {

        border: none !important;
        background: #A82070  !important;
        color: white  !important;
    }




    .rightBTN {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        width: 100px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .centerBTN {
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .leftBTN {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        background: #FFFFFF;
        height: 40px;
        border: 1px solid rgb(225, 225, 225);
        &:hover{
            background: darken($color: #A82070, $amount: 10%);
            color: white;
        }

    }

    .SearchOrder {
        background: rgba(185, 189, 197, 0.31);
        width: 250px;
        height: 38px;
        min-width: 90%;
    }

    .sellOrder-pur {
        height: 40px;
        width: 150px;
    }

    .sellOrderList {
        overflow-x: auto;

        th {
            padding: 12px 3px;
            text-align: right;
            background-color: white;

        }

        tr {

            height: 52px;

            &:nth-child(odd) {
                background-color: rgba(240, 179, 29, 0.08);
            }
        }

        td {
            padding: 0px 3px;
        }
    }
}

@media (max-width : 900px) {
    .SELLORDER {
        height: auto;
        min-height: 200px;
    }
}