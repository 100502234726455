.ITEMSSENT{
    height: auto;
    min-height: 700px;
    border: 1px solid #E6E6E8;
    overflow: hidden;
    
    
    
    .orderList{
        overflow-x: auto ;
        width: 100%;
        
        th{
            padding: 12px 3px;
            text-align: right;
            background-color: white;
             
        }

        tr{
            min-width: 100%;
            height: 52px;
            &:nth-child(odd) {background-color: rgba(240, 179, 29, 0.08);}
        }
        td{
            padding: 0px 3px;
            .acc{
                width: 66px;
                min-width: 66px;
                height: 24px;
                color:darken($color:  #60C08E, $amount: 10%);
                background-color: #75d4a3 ;

            }
            .rej{
                width: 66px;
                min-width: 66px;
                height: 24px;
                color:darken($color: #F85858, $amount: 15%);
                background-color: #f79393;
                line-height: 19px;
                 
            }
        }
    }
}

@media  (max-width : 900px) {
    .ITEMSSENT{
        height: auto;
    }
}