.ADMIN{
    height: auto;
    min-height: 500px;
    border: 1px solid #E6E6E8;
    overflow: hidden;
    .saveChanges{
        background-color: white;
        border: 1px solid #F0B31D;
        color: #F0B31D;
        border-radius: 10px;
        padding: 8px;
        
        &:hover , &:focus , &:active{
            background-color: #F0B31D;
            border: 1px solid white;
            color: white;
            
        }
    }
}